import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";

class ModuleProfileService extends BaseService {

    async getCompany(id: number) : Promise<any> {

        const response = await this.tryExecute(
            this.api.get<any>(`dornick/aziende/${id}`)
        );

        return response ? response.data ? response.data : null : null;
    }

    async getCompanyServices(request: string): Promise<any> {
        const response = await this.tryExecute(
            this.api.get<any>("dornick/getSixtemaServicesForAzienda/" + request)
        );

        return response ? response.data ? response.data : null : null;
    }
}

export const profileService = new ModuleProfileService();