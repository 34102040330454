<template>
<video style="width: 100%" ref="kVideoPlayer" controls oncontextmenu="return false">
    <!-- <source v-for="(s, i) in sources" :key="i" :src="s.src" :type="s.type" /> -->
    Sorry, your browser does not support the video tag.
</video>
<!-- <div>
    <button @click="play">play</button>
    <button @click="pause">pause</button>
    <button @click="stop">stop</button>
    <button @click="setSpeed(0.5)">0.5x</button>
    <button @click="setSpeed(1)">1x</button>
    <button @click="setSpeed(1.5)">1.5x</button>
    <button @click="setSpeed(2)">2x</button>
</div> -->
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
//import { useWebTvStore } from '../store';
import { webTvService } from "../service";
import { MediaPlayer, MediaPlayerClass  } from 'dashjs';


const kVideoPlayer = ref<HTMLVideoElement>(null);

//const tvStore = useWebTvStore();

const props = withDefaults(
    defineProps<{
        idVideo?: string
    }>(), {
    }
);

const sources = ref<{ src: string, type: string }[]>([]);

let _dashPlayer : MediaPlayerClass = null;

watch(() => props.idVideo, async (id) => {
    if(id && id.length > 0) {
        
        const videoSources = await webTvService.getVideoSources(id);

        if (kVideoPlayer.value.readyState) stop();
        if(_dashPlayer) _dashPlayer.reset();

        //kVideoPlayer.value.setAttribute("video_category", val[0].category);

        sources.value = videoSources.map(s => {
            return {
                src: `${s}/manifest.mpd`, //?t=",
                type: "video/mp4"
            }
        });

        if (kVideoPlayer.value.canPlayType('application/vnd.apple.mpegurl')) {
            kVideoPlayer.value.src = videoSources[0];
            // TODO: in onmounted??
            kVideoPlayer.value.addEventListener('loadedmetadata', function () {
                kVideoPlayer.value.play();
            });
        }
        else {
            if(_dashPlayer == null) {
                _dashPlayer = MediaPlayer().create();
            }
            _dashPlayer.initialize(kVideoPlayer.value, sources.value[0].src, true);            
        }
        // HLS.js is not supported on platforms that do not have Media Source
        // Extensions (MSE) enabled.
        //
        // When the browser has built-in HLS support (check using `canPlayType`),
        // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
        // element through the `src` property. This is using the built-in support
        // of the plain video element, without using HLS.js.
        //
        // Note: it would be more normal to wait on the 'canplay' event below however
        // on Safari (where you are most likely to find built-in HLS support) the
        // video.src URL must be on the user-driven white-list before a 'canplay'
        // event will be emitted; the last video event that can be reliably
        // listened-for when the URL is not on the white-list is 'loadedmetadata'.
    }
    // const video = await webTvService.getVideoDetails()
    // tvStore.getLatestVideos();
}, { immediate: true })
  
const play = () => {
    kVideoPlayer.value.play();
};
const pause = () => {
    kVideoPlayer.value.pause();
};
const stop = () => {
    kVideoPlayer.value.pause();
    kVideoPlayer.value.currentTime = 0;
};
const setSpeed = (speed: number) =>  {
    kVideoPlayer.value.playbackRate = speed;
};
</script>