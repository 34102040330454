<template>
    <Page :loading="loading">
        <PageHeader title="Video"></PageHeader>
        
        <!-- <KTabs @clicked="log('clicked', $event)" @changed="log('changed', $event)">
            <KTab name="First tab">
                First tab content
            </KTab>
            <KTab name="Second tab">
                Second tab content
            </KTab>
            <KTab name="Third tab">
                Third tab content
            </KTab>
        </KTabs> -->

        <!-- <KNavScroll>
            <KNavScrollItem name="primo">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nec congue eros. Maecenas sagittis commodo libero nec porta. Nunc semper velit venenatis ligula condimentum ultricies. In hac habitasse platea dictumst. In malesuada pharetra nulla, id aliquam metus egestas ut. Nulla sollicitudin cursus felis, eu sagittis ante porta id. Suspendisse pellentesque ex non sem tincidunt, aliquam rhoncus turpis maximus. Vivamus eget massa turpis. Proin placerat ipsum massa, ac commodo velit tempor quis. In ante augue, sodales ac rhoncus in, ultricies a neque. Morbi non semper felis, at lacinia nibh. Nam quis elit massa. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam laoreet, diam quis blandit porttitor, leo erat semper sem, vel sagittis dolor quam eu magna. Nunc feugiat pretium tempor. Nam eget augue quis tellus viverra malesuada vel ut quam. Cras vehicula rutrum vehicula. Suspendisse efficitur eget purus vitae convallis. Integer euismod pharetra lorem, non ullamcorper lorem euismod vel. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer feugiat et massa nec rhoncus. Morbi vitae metus et sapien suscipit mattis vitae ac ex. Proin pharetra, sem vitae varius malesuada, risus tortor sodales arcu, eu aliquam lorem libero vel urna. Aliquam massa lacus, faucibus quis urna ac, pharetra pretium ex. In facilisis urna non urna luctus, a bibendum tortor facilisis. Mauris sed risus justo. In et erat fermentum eros hendrerit tempor. Quisque accumsan magna ac risus ultricies, vel condimentum ipsum accumsan. Proin blandit mauris sed sodales sollicitudin.
            </KNavScrollItem>
            <KNavScrollItem name="secondo">
                Proin placerat ipsum massa, ac commodo velit tempor quis. In ante augue, sodales ac rhoncus in, ultricies a neque. Morbi non semper felis, at lacinia nibh. Nam quis elit massa. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam laoreet, diam quis blandit porttitor, leo erat semper sem, vel sagittis dolor quam eu magna. Nunc feugiat pretium tempor. Nam eget augue quis tellus viverra malesuada vel ut quam. Cras vehicula rutrum vehicula. Suspendisse efficitur eget purus vitae convallis. Integer euismod pharetra lorem, non ullamcorper lorem euismod vel. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
            </KNavScrollItem>
            <KNavScrollItem name="terzo">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nec congue eros. Maecenas sagittis commodo libero nec porta. Nunc semper velit venenatis ligula condimentum ultricies. In hac habitasse platea dictumst. In malesuada pharetra nulla, id aliquam metus egestas ut. Nulla sollicitudin cursus felis, eu sagittis ante porta id. Suspendisse pellentesque ex non sem tincidunt, aliquam rhoncus turpis maximus. Vivamus eget massa turpis. Proin placerat ipsum massa, ac commodo velit tempor quis. In ante augue, sodales ac rhoncus in, ultricies a neque. Morbi non semper felis, at lacinia nibh. Nam quis elit massa. Interdum et malesuada fames ac ante ipsum primis in faucibus. Aliquam laoreet, diam quis blandit porttitor, leo erat semper sem, vel sagittis dolor quam eu magna. Nunc feugiat pretium tempor. Nam eget augue quis tellus viverra malesuada vel ut quam. Cras vehicula rutrum vehicula. Suspendisse efficitur eget purus vitae convallis. Integer euismod pharetra lorem, non ullamcorper lorem euismod vel. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer feugiat et massa nec rhoncus. Morbi vitae metus et sapien suscipit mattis vitae ac ex. Proin pharetra, sem vitae varius malesuada, risus tortor sodales arcu, eu aliquam lorem libero vel urna. Aliquam massa lacus, faucibus quis urna ac, pharetra pretium ex. In facilisis urna non urna luctus, a bibendum tortor facilisis. Mauris sed risus justo. In et erat fermentum eros hendrerit tempor. Quisque accumsan magna ac risus ultricies, vel condimentum ipsum accumsan. Proin blandit mauris sed sodales sollicitudin.
            </KNavScrollItem>
        </KNavScroll> -->

        <!-- <K3ColContainer rowClass="m-0" col1Class="p-1" col2Class="p-1" col3Class="p-1">
            <template #col1>
                empty
            </template>
            <template #col2>
                empty
            </template>
            <template #col3>
                <KWebTvLatest />
            </template>
        </K3ColContainer> -->
        <KInputSearch v-model="tvStore.advancedSearchTerm" @search="tvStore.advancedSearch" />
        <KWebTvList :items="tvStore.advancedSearchResults" />
    </Page>
</template>

<script setup lang="ts">
import KWebTvLatest from './KWebTvLatest.vue';
import KWebTvList from './KWebTvList.vue';
import { onMounted, ref } from 'vue';
import { useWebTvStore } from '../store';
import { KInputSearch } from '@k-digitale/kappa';

// const log = (evName: string, e: any) => {
//     console.log(evName + JSON.stringify(e));
// }

const tvStore = useWebTvStore();
const loading = ref(false);

onMounted(async () => {
    loading.value = true;
    await tvStore.advancedSearch();
    loading.value = false;
});
</script>

