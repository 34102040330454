<template>
    <div class="card-wrapper">
      <div class="card card-img no-after">
        <div class="img-responsive-wrapper">
          <div class="img-responsive">
            <figure class="img-wrapper">
              <img :src="tvStore.getMediumThumbnailUrl(video)" 
                :title="video.title" :alt="video.title">
            </figure>
          </div>
        </div>
        <div class="card-body" style="padding: 4px 0 24px 0;">
          <h3 class="card-title h5 mb-0" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">{{video.title}}</h3>
          <div>
            <span class="badge bg-primary">{{video.categoryName}}</span>
          </div>
          <!-- <p class="card-text"></p> -->
          <router-link class="read-more" style="right: 0;" :to="('video/' + video.id)">
                <span class="text">Vai al video</span>
                <span class="visually-hidden">{{video.title}}</span>
                <KIcon icon="it-arrow-right" />
          </router-link>
        </div>
      </div>
    </div>
    
    <!--start card-->
    <!-- <div class="card-wrapper">
      <div class="card card-img no-after">
        <div class="img-responsive-wrapper">
          <div class="img-responsive img-responsive-panoramic">
            <figure class="img-wrapper">
              <img src="https://via.placeholder.com/310x94/0066cc/FFFFFF/?text=IMMAGINE%20DI%20ESEMPIO" title="titolo immagine" alt="descrizione immagine">
            </figure>
          </div>
        </div>
        <div class="card-body">
          <h3 class="card-title h5 ">Lorem ipsum dolor sit amet, consectetur adipiscing elit…</h3>
          <p class="card-text"></p>
          <a class="read-more" href="#">
            <span class="text">Leggi di più</span>
            <span class="visually-hidden">su Lorem ipsum dolor sit amet, consectetur adipiscing elit…</span>
            <svg class="icon">
              <use href="/bootstrap-italia/dist/svg/sprites.svg#it-arrow-right"></use>
            </svg></a>
        </div>
      </div>
    </div> -->
    <!--end card-->
</template>

<script setup lang="ts">
import { KIcon } from '@k-digitale/kappa';
import { WebTvVideo } from '../models';
import { useWebTvStore } from '../store';

const props = withDefaults(
  defineProps<{
    video?: WebTvVideo
  }>(), {
  }
);

const tvStore = useWebTvStore();

</script>