<template>
    <Page :loading="loading">
        <PageHeader title="Commesse">
        </PageHeader>
        <!-- <KInputSearch @search="searchEmployees" v-model="store.filter.search" placeholder="Cerca per nome, cognome, settore">
            <div class="d-inline-flex align-items-center" style="margin-left: 20px">Filtri: <KSelect :options="store.optionsCompanies" v-model="store.filter.company" style="margin-bottom: 0"></KSelect></div>
        </KInputSearch> -->

        <DataTable :value="progetti">
            <Column header="Codice" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7;">
                <template #body="item">
                    <span>{{ item.data.codice }}</span>
                </template>
            </Column>
            <Column header="Titolo" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7;">
                <template #body="item">
                    <span>{{ item.data.titolo }}</span>
                </template>
            </Column>
            <Column header="Inizio" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7;">
                <template #body="item">
                    <span>{{ toDateString(item.data.dataInizio) }}</span>
                </template>
            </Column>
            <Column header="Fine" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7;">
                <template #body="item">
                    <span>{{ toDateString(item.data.dataFine) }}</span>
                </template>
            </Column>
            <Column header="Inizio" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7;">
                <template #body="item">
                    <span><a :href="item.data.deepLinkProgetto" target="_blank">APRI</a></span>
                </template>
            </Column>
        </DataTable>
    </Page>
</template>

<script setup lang="ts">

import { useFilters } from '@k-digitale/kappa';
import { onMounted, ref } from 'vue';
import { DprDiProject } from '../models';
import { projectsService } from '../service';

const loading = ref(false);
const progetti = ref<DprDiProject[]>([]);
const { toDateString } = useFilters();

onMounted(async () => {
    loading.value = true;
    try {
        progetti.value = await projectsService.getProjects();
    }
    catch(error) {
        //
    }
    finally {
        loading.value = false;
    }
});
</script>