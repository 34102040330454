import dayjs from "dayjs";
import { usePrimeVue } from 'primevue/config';

export function useViewStatus() {
    const primevue = usePrimeVue();
    const updateLocale = () => {
        primevue.config.locale.monthNames = ["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"];
        primevue.config.locale.monthNamesShort= ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu","Lug", "Ago", "Set", "Ott", "Nov", "Dec"];
        primevue.config.locale.dayNamesShort= ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven","Sab"];
        primevue.config.locale.dayNamesMin= ["Do", "Lu", "Ma", "Me", "Gi", "Ve","Sa"];
        primevue.config.locale.today = "Oggi";
        primevue.config.locale.clear = "Cancella";
    }

    const getStatusCodeFromDate = (date : Date, monthsThreshold = 2) : string => {
        let stateCode = "success";
        if(dayjs() > dayjs(date)){
            stateCode = "danger";
        }
        if(dayjs(date).diff(dayjs(), 'month') >= 0 && dayjs(date).diff(dayjs(), 'month') < monthsThreshold){
            stateCode = "warning";
        }
        return stateCode;
    }
    const getStatusLabelFromDate = (date : Date, monthsThreshold = 2) : string => {
        let stateDescription = "Valido";
        if(dayjs() > dayjs(date)){
            stateDescription = "Scaduto";
        }
        if(dayjs(date).diff(dayjs(), 'month') >= 0 && dayjs(date).diff(dayjs(), 'month') < monthsThreshold){
            stateDescription = "In Scadenza";
        }
        return stateDescription;
    }

    const isExpiredOrInExpiring = (date : Date, monthsThreshold = 2) : boolean => {
        if(dayjs() > dayjs(date)){
            return true;
        }
        if(dayjs(date).diff(dayjs(), 'month') >= 0 && dayjs(date).diff(dayjs(), 'month') < monthsThreshold){
            return true;
        }
        return false;
    }


    const getWorkerSexDropdownOptions = ()=> {
        return [
            {
                name: "M",
                value: "M"
            },
            {
                name: "F",
                value: "F"
            },
        ]
    };

    return {
        getStatusCodeFromDate,
        getStatusLabelFromDate,
        isExpiredOrInExpiring,
        updateLocale,
        getWorkerSexDropdownOptions
    }
}