import { GlobalSearchPlugin, GlobalSearchResult } from "@k-digitale/kappa";
import { useWebTvStore } from './store';

class WebtvGlobalSearchPlugin implements GlobalSearchPlugin{

    source = "web-tv";
    sourceDescription = "Web TV";

    _lastSearch = "";
    _lastSkip = 0;

    

    async searchAsync(searchText: string) : Promise<GlobalSearchResult[]> {
        this._lastSearch = searchText;
        return await this._searchVideos();
    }

    async loadMoreAsync() : Promise<GlobalSearchResult[]> {
        return await this._searchVideos();
    }

    async _searchVideos() : Promise<GlobalSearchResult[]> {
        
        const tvStore = useWebTvStore();
        const videos = await tvStore.searchVideos(this._lastSearch, 5, this._lastSkip);

        const results = videos.map(video => { return {
            source: this.source,
            title: video.title,
            description: video.description,
            thumbnail: tvStore.getSmallThumbnailUrl(video),
            url: "/video/" + video.id
        }});
        
        this._lastSkip = this._lastSkip + videos.length;;
        return results;
    }    
}

export default new WebtvGlobalSearchPlugin();