import { RouteRecordRaw } from "vue-router";

import Servizi from './pages/service.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/service',
        component: Servizi,
        meta: { 
            requiresAuth: true,
            menu: {
                order: 1,
                label: 'Servizi',
                iconAsset: 'icons/ico_servizi.svg'
            }
        }
    }
];

export default routes;