import { RouteRecordRaw } from 'vue-router';

/*** */
const optionalModules: { [index: string]: RouteRecordRaw[] } = {
};

import HomePage from './pages/HomeCompany.vue';
optionalModules.mod_home = [
    {
        path: '/',
        component: HomePage,
        meta: {
            requiresAuth: true,
            menu: {
                order: 1,
                label: 'Bacheca',
                //icon: 'Home'
                iconAsset: "/icons/ico_bacheca.svg"
            }
        }
    }
];


import profileRoutes from './modules/profile/routes';
optionalModules.mod_profile = profileRoutes;

import srvRoutes from './modules/service/routes';
optionalModules.mod_service = srvRoutes;

import prjRoutes from './modules/projects/routes';
optionalModules.mod_project = prjRoutes;

import videoRoutes from './modules/webtv/routes';
optionalModules.mod_video = videoRoutes;

import commRoutes from './modules/communication/routes';
optionalModules.mod_comm = commRoutes;

import glossaryRoutes from './modules/glossary/routes';
optionalModules.mod_glossary = glossaryRoutes;

export { optionalModules };