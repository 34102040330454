import { GlobalSearchPlugin, GlobalSearchResult } from "@k-digitale/kappa";
import { SearchDMSDocumentsRequest } from "./models/Models";
import { dmsService } from './services/service';

class DMSSearchProvider implements GlobalSearchPlugin {

    source = "DMS";
    sourceDescription = "Document Management System";

    _lastSearch = "";

    async searchAsync(searchText: string): Promise<GlobalSearchResult[]> {

        const request: SearchDMSDocumentsRequest = {
            searchString: searchText,
            searchInFileName: true,
            searchInDescription: false,
            mine: true,
            inMySpaces: true,
            sharedWithMe: true,
            currentPageIndex: 0,
            pageSize: 10
        }
        this._lastSearch = searchText;
        const response = await dmsService.searchDocuments(request);
        if (response != null) {
            const results = response.data.items.map(doc => {
                return {
                    source: this.source,
                    title: doc.fileName,
                    description: doc.folderPath,
                    url: "/dms/" + doc.id
                }
            });

            return results;
        }
        else {
            return [];
        }
    }

    async loadMoreAsync(): Promise<GlobalSearchResult[]> {
        const results: GlobalSearchResult[] = [];
        return results;
    }
}

export default new DMSSearchProvider();