import { ApiPagedResult, BaseService } from "@k-digitale/kappa";
import { SendSupportEmailRequest, SendSupportMessageRequest } from "./WholesaleModels";

class WholesaleService extends BaseService {

    //NOTIFICATIONS
    async sendSupportEmail(request: SendSupportEmailRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("cesf/notifications/send-email-to-cesf", request)
        );

        //return response!.data;
        return response!.data.success;
    }

    async sendSupportMessage(request: SendSupportMessageRequest): Promise<boolean> {
        const response = await this.tryExecute(
            this.api.post<any>("cesf/notifications/send-message-to-cesf", request)
        );

        //return response!.data;
        return response!.data.success;
    }
}

export const wholesaleService = new WholesaleService();
