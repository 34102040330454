<template>
    <Page>
        <PageHeaderFull title="Benvenuto" :titleUnderline="user && user.displayName" >
            <template #secondary-actions>
                Servizi attivi: <strong class="text-primary" style="width: auto;">nessuno</strong>
            </template>
        </PageHeaderFull>

        <div class="row my-4">
            <div class="col-12 p-0">
                <!-- <KSectionHeader title="Notifiche"></KSectionHeader>
                <KSpinner :loading="loadingNotifications">
                    <KNotificationCard :title="notifica.title" :body="notifica.body"
                        v-for="(notifica, index) in notifichedocumentiItems" :key="index" :showPrimaryButton="true"
                        :showSecondaryButton="true" :primaryButton="{ label: 'DETTAGLI' }"
                        :secondaryButton="{ label: 'APRI PDF' }"
                        @on-primary-button-click="onNotificationPrimaryButtonClick(notifica)"
                        @on-secondary-button-click="onNotificationSecondaryButtonClick(notifica)">
                        <template #pre-info-title>
                            <span class="mr-3 uppercase notifica-data px-3">
                                {{ toDDMMMMString(notifica.data) }}
                            </span>
                        </template>
                    </KNotificationCard>
                </KSpinner> -->
            </div>
        </div>

        <div class="row my-4">
            <div class="col-12 p-0">

                <KTabs>
                    <KTab name="Commesse">
                        Nessuna commessa presente
                    </KTab>
                    <KTab name="Contratti">
                        <DocumentsDataTable :pageSize="5" :showPaginator="false" :homeMode="true"></DocumentsDataTable>
                    </KTab>
                    <KTab name="Comunicazioni">
                        Nessuna comunicazione presente
                    </KTab>
                </KTabs>
            </div>
        </div>

        <BannerEsperto />

        <div class="row my-4">
            <div class="col-8">
                <!-- <KSectionHeader title="Osservatorio sull'economia regionale" />
                <div class="col-12 p-0">
                    <K2ColContainer :col1Class="'p-3'" :col2Class="'p-3'">
                        <template v-if="news1" #col1>
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="category-top">
                                            <a class="category text-uppercase" href="#">{{ news1.title }}</a>
                                        </div>
                                        <h5 class="card-title ">{{ news1.description }}</h5>
                                        <a class="read-more" :href="news1.url" role="button" :title="news1.title">
                                            <span class="text">SCOPRI DI PIU'
                                                <span class="visually-hidden">Verifica documentazione aziendale sulla
                                                    sicurezza</span>
                                            </span>
                                            <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="news2" #col2>
                            <div class="card-wrapper">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="category-top">
                                            <a class="category text-uppercase" href="#">{{ news2.title }}</a>
                                        </div>
                                        <h5 class="card-title ">{{ news2.description }}</h5>
                                        <a :href="news2.url" class="read-more" role="button"
                                            :title="'Verifica documentazione aziendale sulla sicurezza'">
                                            <span class="text">SCOPRI DI PIU'
                                                <span class="visually-hidden">Verifica documentazione aziendale sulla
                                                    sicurezza</span>
                                            </span>
                                            <KIcon class="icon-primary icon-xs" icon="it-arrow-right" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </K2ColContainer>
                </div> -->
            </div>
            <div class="col-4">
                <KSectionHeader title="WebTV" />
                <div class="col-12 p-0">
                    <KWebTvLatest></KWebTvLatest>
                </div>
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useFilters, useUserStore, cmsService } from "@k-digitale/kappa";
import { useWholesaleUser, useViewStatus } from "@/composables";
import { dmsService } from "@/modules/dms/services/service";
import { useCnaCmsStore } from "@/store";

import KWebTvLatest from "@/modules/webtv/pages/KWebTvLatest.vue";
import BannerEsperto from "@/components/BannerEsperto.vue";
import DocumentsDataTable from '@/modules/dms/components/DocumentsDataTable.vue';
import { DMSDocumentOrFolder, DownloadDocumentDMSRequest, SearchDMSDocumentsRequest } from "@/modules/dms/models/Models";

const { isUserCompany, wholesaleUser } = useWholesaleUser();
const viewStatusHelper = useViewStatus();

const router = useRouter();
const { toDDMMMMString, toDateString } = useFilters();
const { user } = useUserStore();

//STORES
const cnaCmsStore = useCnaCmsStore();

//REFS
const loading = ref(true);
const loadingNotifications = ref(true);
const corsiItems = ref<any[]>([]);
const notifichedocumentiItems = ref<any[]>([]);


//COMPUTED
const link = computed(() => import.meta.env.VITE_CMS_DETAIL_BASE_URL);
const newsByPublishDate = computed(() => {
    return cnaCmsStore.news.sort((a, b) => {
        return new Date(b.publishedDate).getTime() - new Date(a.publishedDate).getTime();
    });
})
const news1 = computed(() => {
    if (newsByPublishDate.value.length < 1) {
        return null;
    }
    const news = newsByPublishDate.value[0];
    return {
        title: news.name,
        description: news.contents.find(c => c.code == "text")?.value.value,
        url: import.meta.env.VITE_CMS_DETAIL_BASE_URL + news.url,
    };
});
const news2 = computed(() => {
    if (newsByPublishDate.value.length < 2) {
        return null;
    }
    const news = newsByPublishDate.value[1];
    return {
        title: news.name,
        description: news.contents.find(c => c.code == "text")?.value.value,
        url: import.meta.env.VITE_CMS_DETAIL_BASE_URL + news.url,
    };
});

const coursesExpired = computed(() => {
    return corsiItems.value.filter(function (x: any) {
        return viewStatusHelper.isExpiredOrInExpiring(x.dataScadenza);
    }).length;
});

//VUE
onMounted(async () => {

    if (isUserCompany.value) {
        loading.value = true;

        // TODO: chiamate parallele
        try {
            //companyData.value = await learningExtensionService.getCompanyExtByEduCompanyId(cnaUser.value.companyId);
        } catch (error) {
            companyData.value = null;
        }

        await cnaCmsStore.getCircolari();
        await cnaCmsStore.getNews();
        loading.value = false;
    }

    // TODO: solo temporaneo
    const filterDMSDocument: SearchDMSDocumentsRequest = {
        pageSize: 3,
        mine: true,
        searchString: "",
        searchInFileName: false,
        searchInDescription: false,
        inMySpaces: false,
        sharedWithMe: true,
        currentPageIndex: 0
    }
    loadingNotifications.value = true;
    const documentResponse = await dmsService.searchDocuments(filterDMSDocument);
    if (documentResponse.success) {
        let documents = documentResponse.data.items;
        documents = documents.map(obj => ({ ...obj, name: obj.fileName }));
        documents.forEach(element => {
            notifichedocumentiItems.value.push({
                id: element.id,
                title: element.name,
                data: element.creationDate,
                elementName: element.name,
                body: 'Nuovo documento creato'
            });
        });
    }
    loadingNotifications.value = false;
    //
});


const goToCircolareDetail = (item: any) => {
    router.push({
        path: `/communication/detail/${item.id}`
    });
};

const isStateVisible = (item: any) => {
    return viewStatusHelper.isExpiredOrInExpiring(item.dataScadenza);
};

const goToIscrizioneCorso = (item: any) => {
    // router.push({
    //     path: `/employee/detail/${item.id}`
    // });
};

const goToCourseDetail = () => {
    router.push({
        path: `/course/registration`,
    });
};

const companyData = ref<any>({});

//METHODS

const onNotificationPrimaryButtonClick: any = function (doc: any) {
    router.push({
        path: `/dms/${doc.id}`,
    });
};

const onNotificationSecondaryButtonClick: any = async function (doc: any) {
    await downloadDocument(doc.elementName, doc.id);
};

const downloadDocument = async (documentName: string, documentId: number, versionId?: number) => {
    const downloadRequest: DownloadDocumentDMSRequest = { documentId: documentId, versionId: versionId }
    const downloadResponse = await dmsService.downloadDocument(downloadRequest);
    console.log(downloadResponse);
    let blob = new Blob([downloadResponse.data], { type: downloadResponse.headers['content-type'] });
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = documentName;
    link.click()
    URL.revokeObjectURL(link.href)
}

</script>

<style scoped lang="scss">
.first-col-label {
    background-color: #e3e3e3;
    border-radius: 5px;
    padding: 0px 5px;
}

.third-col-label {
    color: #0066cc;
}

.second-col-label {
    color: #00ba07;
}

.state-code {
    background-color: #00ba07 !important;
    width: 14px;
    height: 14px;
    border-radius: 8px;
}

.notify-title {
    font-size: 28px;
}

.notifica-data {
    color: #757575;
    font-size: 14px;
    background-color: #f8f8f8;
    border-radius: 4px;
}

.tabview-custom {

    i,
    span {
        vertical-align: middle;
    }

    span {
        margin: 0 0.5rem;
    }
}

.p-tabview p {
    line-height: 1.5;
    margin: 0;
}

.state-code-label {
    width: 12px;
    height: 12px;
    border-radius: 6px;
}

.tb-lista-corsi-home,
.tb-lista-cantieri-home {
    .p-datatable-wrapper {
        table {
            tbody {
                tr {
                    td:first-child {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}
</style>
