import { ApiPagedListResult, ApiResult, BaseService } from "@k-digitale/kappa";
import { DprDiProject } from "./models";

class ProjectsService extends BaseService {

    async getProjects(): Promise<DprDiProject[]> {
        const params = {};

        const response = await this.tryExecute(
            this.api.get<DprDiProject>("dprdi/projects", { params })
        );

        if(response && response.status == 200) return (response.data as any).items;
        
        return null;
    }
}

export const projectsService = new ProjectsService();
