<template>
    <nav v-if="!loadingDocumentsAndFolders && showBreadcrumb && !homeMode" class="breadcrumb-container"
        aria-label="Percorso di navigazione">
        <ol class="breadcrumb">
            <li v-for="(item, index) in documentsAndFoldersResponse.data.folderContents.folderPath" :key="index"
                @click="setFolderIdAndGetContent(item.id)" class="breadcrumb-item"><a href="#">{{
                        item.elementName
                }}</a><span class="separator">&gt;</span></li>
        </ol>
    </nav>
    <DataTable :value="documentsAndFolders" :loading="loadingDocumentsAndFolders">
        <Column header="Tipologia" header-class="fw-bold py-0 text-uppercase text-nowrap"
            header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px" body-style="border-color:#C7C7C7">
            <template #body="slotProps">
                <span v-if="slotProps.data.isFolder">
                    <KIcon icon="it-folder"></KIcon>
                </span>
                <span v-else>
                    <KIcon icon="it-file"></KIcon>
                </span>
            </template>
        </Column>
        <Column header="Nome" header-class="fw-bold py-0 text-uppercase text-nowrap"
            header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
            body-style="border-color:#C7C7C7; max-width: 500px">
            <template #body="slotProps">
                <span @click="onRowSelected(slotProps.data)" :class="!slotProps.data.isFolder ? 'fw-bold' : ''"
                    style="cursor:pointer">{{
                            slotProps.data.name
                    }}</span>
            </template>
        </Column>
        <Column header="Categoria" header-class="fw-bold py-0 text-uppercase text-nowrap"
            header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px" body-style="border-color:#C7C7C7;">
            <template #body="slotProps">
                <span v-if="!slotProps.data.isFolder">{{ getDocumentCategory(slotProps.data.categoryId) }}</span>
            </template>
        </Column>
        <Column header="Data creazione" header-class="fw-bold py-0 text-uppercase text-nowrap"
            header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px" body-style="border-color:#C7C7C7;">
            <template #body="slotProps">
                <span>{{ getFilteredDate(slotProps.data.creationDate) }}</span>
            </template>
        </Column>
        <Column header-style="background-color:#F8F9F9" body-style="border-color:#C7C7C7;text-align:right">
            <template #body="slotProps">
                <KDataTableActions :showIcons="true" :showDetail="!slotProps.data.isFolder"
                    @doc-detail="showDocumentDetail(slotProps.data.id)" :showDownload="!slotProps.data.isFolder"
                    @doc-download="downloadDocument(slotProps.data.name, slotProps.data.id, slotProps.data.versionId)"
                    :showShare="false" :showCopyLink="false" :showDelete="false" :showRenameFolder="false"
                    :showDeleteFolder="false">
                </KDataTableActions>
            </template>
        </Column>
    </DataTable>
    <KModalSlide v-model="showShareDocumentModal" @closed="closeShareDocumentModal" title="Condividi in Extranet">
        <div>
            <label class="fw-bold greyText">Documento</label>
            <div class="row">
                <div class="col-1 d-flex justify-content-center align-items-center">
                    <KIcon icon="it-file"></KIcon>
                </div>
                <div class="col-11 d-flex flex-column">
                    <span class="fw-bold">{{ selectedDocument.fileName }}</span>
                    <span><small>{{ getFilteredDate(selectedDocument.creationDate) }}</small></span>
                </div>
            </div>
            <div class="row mt-3">
                <label class="fw-bold greyText">Destinatari</label>
                <div class="col-12">
                    <KInputChips v-model="toAddresses" :type="InputChipsType"></KInputChips>
                </div>
            </div>
            <div class="row mt-3">
                <label class="fw-bold greyText">Azione richiesta</label>
                <div class="col-12 py-0">
                    <div class="form-check form-check-inline" v-for="(action, index) in availableDocumentActions"
                        :key="action">
                        <input :id="'ch_' + index" type="checkbox">
                        <label :for="'ch_' + index">{{ action }}</label>
                    </div>
                </div>
            </div>
            <div class="row mt-5">
                <div class="col-12">
                    <KInputTextArea class="mb-0" labelClass="fw-bold pl-0 pb-2" labelStyle="font-size:unset"
                        labelText="Messaggio (facoltativo)" placeholder="Inserisci il tuo messaggio"></KInputTextArea>
                </div>
            </div>
            <div class="row mt-3">
                <label class="fw-bold greyText">Notifiche</label>
                <div class="col-12">
                    <div class="form-check col-12 my-0">
                        <div class="toggles">
                            <label for="toggleNotifyOnAction">
                                Inviami notifiche quando viene effettuata un'azione
                                <input type="checkbox" id="toggleNotifyOnAction">
                                <span class="lever"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-check col-12 my-0">
                        <div class="toggles">
                            <label for="toggleAddPeopleToNotifications">
                                Aggiungi colleghi all'invio notifiche
                                <input type="checkbox" id="toggleAddPeopleToNotifications">
                                <span class="lever leverRight"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <label class="fw-bold greyText">Collega</label>
                <div class="col-12">
                    <KInputChips v-model="linkAddresses" :type="InputChipsType.email"></KInputChips>
                </div>
            </div>

        </div>
        <template #actions>
            <button class="btn btn-outline-primary btn-xs text-uppercase" type="button"
                @click="closeShareDocumentModal">Annulla</button>
            <button class="btn btn-primary btn-xs text-uppercase" type="button">Condividi</button>
        </template>
    </KModalSlide>
    <KModalSlide v-model="showDetailDocumentModal" @closed="closeDetailDocumentModal" title="Dettaglio documento">
        <KSpinner :loading="loadingDocumentDetail">
            <KTabs v-if="documentDetail">
                <KTab name="Principale">
                    <div class="row">
                        <div class="col-2 d-flex justify-content-center align-items-center">
                            <KIcon icon="it-file" class="icon-xl"></KIcon>
                        </div>
                        <div class="col-8 d-flex flex-column">
                            <span class="fw-bold">{{ documentDetail.fileName }}</span>
                            <span><small>{{ getFilteredDate(documentDetail.creationDate) }}</small></span>
                        </div>
                        <div class="col-2 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-primary btn-xs"
                                @click="downloadDocument(documentDetail.fileName, documentDetail.id)">
                                <KIcon icon="it-download" class="icon-white icon-sm">
                                </KIcon><span class="visually-hidden">Scarica file versione
                                    {{ documentDetail.currentVersion }}</span>
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label class="fw-bold greyText">Proprietario</label>
                            <p class="blackText">{{ documentDetail.creatorName }}</p>
                        </div>
                        <div class="col-12">
                            <label class="fw-bold greyText">Versione corrente</label>
                            <p class="blackText">{{ documentDetail.currentVersion }}</p>
                        </div>
                        <div class="col-12">
                            <label class="fw-bold greyText">Versione corrente creata da</label>
                            <p class="blackText">{{
                                    documentDetail.versions.find(c => c.version ===
                                        documentDetail.currentVersion).creatorName
                            }}</p>
                        </div>
                        <div class="col-12">
                            <label class="fw-bold greyText">Versione corrente ultima modifica</label>
                            <p class="blackText">{{
                                    getFilteredDate(documentDetail.versions.find(c => c.version ===
                                        documentDetail.currentVersion).creationTime)
                            }}</p>
                        </div>
                    </div>
<!--                     <div class="row mt-2">
                        <div class="col-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-danger btn-progress text-uppercase"
                                @click="deleteDocument(documentDetail.id)">
                                Elimina <div class="progress progress-indeterminate" v-if="deletingDocumentDetail">
                                    <span class="visually-hidden">Eliminazione documento in corso</span>
                                    <div class="progress-bar" role="progressbar"></div>
                                </div>
                            </button>
                        </div>
                    </div> -->
                </KTab>
                <KTab name="Propriet&agrave;">
                    <div class="row mt-2">
                        <div class="col-12">
                            <KSelect labelText="Categoria" :options="categoriesForSelect"
                                v-model="documentDetail.categoryId"></KSelect>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <KInputTextArea class="mb-0" labelClass="pb-2" labelText="Descrizione"
                                placeholder="Inserisci la descrizione del file" v-model="documentDetail.description">
                            </KInputTextArea>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12"></div>
                        <label class="fw-bold greyText">Tags</label>
                        <div class="col-12">
                            <KInputChips v-model="documentDetail.tags"></KInputChips>
                        </div>
                    </div>
<!--                     <div class="row mt-2">
                        <div class="col-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-primary btn-progress text-uppercase"
                                @click="saveDocumentInfo">
                                Salva <div class="progress progress-indeterminate"
                                    v-if="savingDocumentDetailProperties">
                                    <span class="visually-hidden">Salvataggio in corso</span>
                                    <div class="progress-bar" role="progressbar"></div>
                                </div>
                            </button>
                        </div>
                    </div> -->
                </KTab>
                <KTab name="Versioni">
                    <DataTable :value="documentDetail.versions">
                        <Column field="version" header="Versione" header-class="fw-bold py-0 text-uppercase text-nowrap"
                            header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px"
                            body-style="border-color:#C7C7C7;">
                        </Column>
                        <Column header="Data creazione" header-class="fw-bold py-0 text-uppercase text-nowrap"
                            header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px"
                            body-class="text-center" body-style="border-color:#C7C7C7;">
                            <template #body="slotProps">
                                <span>{{ getFilteredDate(slotProps.data.creationTime) }}</span>
                            </template>
                        </Column>
                        <Column field="creatorName" header="Creata da"
                            header-class="fw-bold py-0 text-uppercase text-nowrap"
                            header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px"
                            body-style="border-color:#C7C7C7;">
                        </Column>
                        <Column field="downloads" header="Scaricamenti"
                            header-class="fw-bold py-0 text-uppercase text-nowrap"
                            header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px" body-class="text-end"
                            body-style="border-color:#C7C7C7;">
                        </Column>
                        <Column header-style="background-color:#F8F9F9"
                            body-style="border-color:#C7C7C7;text-align:right">
                            <template #body="slotProps">
                                <button type="button" class="btn btn-primary btn-xs"
                                    @click="downloadDocument(documentDetail.fileName, documentDetail.id, slotProps.data.version)">
                                    <KIcon icon="it-download" class="icon-white icon-sm">
                                    </KIcon><span class="visually-hidden">Scarica file versione
                                        {{ slotProps.data.version }}</span>
                                </button>
                            </template>
                        </Column>
                    </DataTable>
                </KTab>
            </KTabs>
        </KSpinner>
    </KModalSlide>
</template>

<script setup lang="ts">
import { KIcon, KTabs, useFilters, InputChipsType, ApiResult } from '@k-digitale/kappa';
import { ref, onMounted, computed, watch } from 'vue';
import { DMSCategory, DMSDocumentOrFolder, GetCategoriesDMSRequest, GetFolderContentDMSResponse, GetFolderContentDMSRequest, DownloadDocumentDMSRequest, SearchDMSDocumentsRequest, DMSDocument, GetDocumentDMSRequest, SetDocumentPropertiesDMSRequest, DeleteDocumentDMSRequest, DeleteFolderDMSRequest, DMSVirtualFileSystemFolder } from '../models/Models';
import { dmsService } from "../services/service";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

const toast = useToast();
const confirm = useConfirm();

const loading = ref(false);
const loadingDocumentsAndFolders = ref(true);
const loadingDocumentDetail = ref(true);
const savingDocumentDetailProperties = ref(false);
const deletingDocumentDetail = ref(false);

const filter = ref({} as GetFolderContentDMSRequest);
const filterCategories = ref({} as GetCategoriesDMSRequest);
const filterDMSDocument = ref({} as SearchDMSDocumentsRequest);

const documentsAndFoldersResponse = ref({} as ApiResult<GetFolderContentDMSResponse>);

const documentsAndFolders = ref([] as DMSDocumentOrFolder[]);
const documentDetail = ref<DMSDocument>(null);
const categories = ref([] as DMSCategory[]);
const availableDocumentActions = ref([]);
const selectedDocument = ref({} as DMSDocumentOrFolder);
const searchText = ref("");

const linkAddresses = ref([]);
const toAddresses = ref([]);

const showShareDocumentModal = ref(false);
const showDetailDocumentModal = ref(false);

const props = withDefaults(
    defineProps<{
        showPaginator?: boolean,
        showBreadcrumb?: boolean,
        homeMode?: boolean,
        pageSize?: number,
        newDocumentId?: number,
        newFolderId?: number,
        documentDetailId?: number
    }>(),
    {
        showPaginator: true,
        showBreadcrumb: true,
        homeMode: false,
        pageSize: 10,
        newDocumentId: null,
        newFolderId: null,
        documentDetailId: null
    }
);

const emit = defineEmits<{
    (e: 'folder-changed', value: DMSVirtualFileSystemFolder): void
    (e: 'folder-rename', value: { folderId: number, folderName: string }): void
}>()

watch(() => props.documentDetailId, async (newVal) => {
    loading.value = true;
    try {
        if (newVal && newVal > 0) {
            await showDocumentDetail(newVal);
            await setFolderIdAndGetContent(documentDetail.value.folderId)
        }
        else {
            await loadItems();
        }
    }
    finally {
        loading.value = false;
    }
});

watch(() => props.newDocumentId, async (newVal) => {
    if (newVal) {
        await loadItems();
        await showDocumentDetail(newVal);
    }
});

watch(() => props.newFolderId, async (newVal) => {
    if (newVal) {
        filter.value.folderId = newVal;
        await getFolderContent();
    }
});

onMounted(async () => {
    getAvailableActions();
    await getCategories();
    if (props.homeMode) {
        await loadItems();
    }
});

const loadItems = async () => {
    if (props.homeMode) {
        filterDMSDocument.value.pageSize = props.pageSize;
        filterDMSDocument.value.mine = true;
        await getDocuments();
    }
    else {
        await getFolderContent();
    }
}

const closeShareDocumentModal = () => {
    showShareDocumentModal.value = false;
}

const closeDetailDocumentModal = () => {
    showDetailDocumentModal.value = false;
}

const showDocumentDetail = async (documentId: number) => {
    showDetailDocumentModal.value = true;
    loadingDocumentDetail.value = true;
    documentDetail.value = null;
    var filterDocumentDetail: GetDocumentDMSRequest = { documentId: documentId }
    var documentDetailResponse: ApiResult<DMSDocument> = await dmsService.getDocumentDetail(filterDocumentDetail);

    if (documentDetailResponse.success) {
        documentDetail.value = documentDetailResponse.data;
    }
    else {
        documentDetail.value = null;
        console.log(documentDetailResponse.details);
    }
    loadingDocumentDetail.value = false;
}

const showConfirmDeleteDocument = (document: any) => {
    confirm.require({
        message: `Confermare l'eliminazione del file ${document.fileName}?`,
        rejectClass: 'p-button-outlined',
        acceptClass: 'p-button-primary',
        acceptLabel: "SI",
        rejectLabel: "NO",

        accept: async () => {
            await deleteDocument(document.id);
        },
        reject: () => {
            //
        },
    });
}

const deleteDocument = async (documentId: number) => {
    deletingDocumentDetail.value = true;
    var deleteDocumentDetail: DeleteDocumentDMSRequest = { documentId: documentId }
    var deleteDocumentDetailResponse: ApiResult<boolean> = await dmsService.deleteDocument(deleteDocumentDetail);

    if (deleteDocumentDetailResponse.success) {
        toast.add({
            severity: "success",
            summary: "Operazione completata con successo",
            detail: "File eliminato correttamente",
            life: 3000,
        });
        await loadItems();
        closeDetailDocumentModal();
        documentDetail.value = null;
        deletingDocumentDetail.value = false;
    }
    else {
        toast.add({
            severity: "error",
            summary: "Errore durante l'operazione richiesta",
            detail: deleteDocumentDetailResponse.details.map(c => c.message).join(),
            life: 3000,
        });
    }
}

const deleteFolder = async (folderId: number) => {
    const deleteFolderRequest: DeleteFolderDMSRequest = { folderId: folderId }
    const deleteFolderResponse: ApiResult<boolean> = await dmsService.deleteFolder(deleteFolderRequest);

    if (deleteFolderResponse.success) {
        toast.add({
            severity: "success",
            summary: "Operazione completata con successo",
            detail: "Cartella eliminata correttamente",
            life: 3000,
        });
        await loadItems();
    }
    else {
        console.log(deleteFolderResponse.details);
        toast.add({
            severity: "error",
            summary: "Errore durante l'operazione richiesta",
            detail: deleteFolderResponse.details.map(c => c.message).join(),
            life: 3000,
        });
    }
}

const downloadDocument = async (documentName: string, documentId: number, versionId?: number) => {
    const downloadRequest: DownloadDocumentDMSRequest = { documentId: documentId, versionId: versionId }
    const downloadResponse = await dmsService.downloadDocument(downloadRequest);
    console.log(downloadResponse);
    let blob = new Blob([downloadResponse.data], { type: downloadResponse.headers['content-type'] });
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = documentName;
    link.click()
    URL.revokeObjectURL(link.href)
}

const shareDocument = (documentId: number) => {
    setSelectedDocument(documentId);
    showShareDocumentModal.value = true;
}

const copylinkDocument = (documentId: string) => {
    console.log("Copy link premuto: " + documentId);
}

const renameFolder = (folder: DMSDocumentOrFolder) => {
    emit('folder-rename', { folderId: folder.id, folderName: folder.elementName });
}

const getFolderContent = async () => {
    loadingDocumentsAndFolders.value = true;
    documentsAndFoldersResponse.value = await dmsService.getFolderContent(filter.value);
    documentsAndFolders.value = [];
    if (documentsAndFoldersResponse.value.success) {
        var copiedArray = JSON.parse(JSON.stringify(documentsAndFoldersResponse.value.data.folderContents.folderPath));
        let currentFolder = copiedArray.pop();
        emit('folder-changed', currentFolder);
        let documents = documentsAndFoldersResponse.value.data.folderContents.documents;
        documents = documents.map(obj => ({ ...obj, name: obj.fileName }));
        let folders = documentsAndFoldersResponse.value.data.folderContents.folders;
        folders = folders.map(obj => ({ ...obj, name: obj.elementName, isFolder: true }));
        documentsAndFolders.value = documents.concat(folders);
    }
    else {
        toast.add({
            severity: "error",
            summary: "Errore durante l'operazione richiesta",
            detail: documentsAndFoldersResponse.value.details.map(c => c.message).join(),
            life: 3000,
        });
    }
    loadingDocumentsAndFolders.value = false;
}

const getDocuments = async () => {
    loadingDocumentsAndFolders.value = true;
    documentsAndFolders.value = [];
    const documentResponse = await dmsService.searchDocuments(filterDMSDocument.value);
    if (documentResponse.success) {
        let documents = documentResponse.data.items;
        documents = documents.map(obj => ({ ...obj, name: obj.fileName }));
        documentsAndFolders.value = documents;
    }
    else {
        documentsAndFolders.value = [];
        toast.add({
            severity: "error",
            summary: "Errore durante l'operazione richiesta",
            detail: documentResponse.details.map(c => c.message).join(),
            life: 3000,
        });
    }
    loadingDocumentsAndFolders.value = false;
}

const getFilteredDate = (date: string) => {
    return useFilters().toDateString(date);
}

const getDocumentCategoryColor = (categoryId: number) => {
    var color = "#00BA07";
    switch (categoryId) {
        case 1:
            color = "#00BA07";
            break;
        case 2:
            color = "#FF8B00";
            break;
    }
    return color;
}

const getDocumentCategory = (categoryId: number) => {
    if (categories.value && categories.value.length > 0) {
        return categories.value.find(s => s.id === categoryId).localeDescription;
    }
    return "";
}

const setSelectedDocument = (documentId: number) => {
    selectedDocument.value = null;
    if (documentsAndFolders.value && documentsAndFolders.value.length > 0) {
        selectedDocument.value = documentsAndFolders.value.find(d => d.id === documentId);
    }
}

const getAvailableActions = () => {
    availableDocumentActions.value = ["Scarica", "Prendi visione", "Approva", "Autorizza pagamento"];
}

const getCategories = async () => {
    filterCategories.value.locale = "it-IT";
    const categoriesResponse = await dmsService.getCategories(filterCategories.value);
    if (categoriesResponse.success) {
        categories.value = categoriesResponse.data.categories;
    }
    else {
        categories.value = [];
    }
}

const categoriesForSelect = computed(() => {
    if (categories.value && categories.value.length > 0) {
        return categories.value.map(obj => ({ name: obj.localeDescription, value: obj.id }))
    }
    else return [];
});

const onRowSelected = async (dof: DMSDocumentOrFolder) => {
    if (!dof.isFolder) {
        showDocumentDetail(dof.id);
    }
    else {
        setFolderIdAndGetContent(dof.id);
    }
}

const setFolderIdAndGetContent = async (folderId: number) => {
    filter.value.folderId = folderId;
    await getFolderContent();
}

const saveDocumentInfo = async () => {
    const documentId = documentDetail.value.id;
    savingDocumentDetailProperties.value = true;

    let saveDocumentRequest: SetDocumentPropertiesDMSRequest = {
        documentId: documentId,
        description: documentDetail.value.description,
        categoryId: documentDetail.value.categoryId,
        tags: documentDetail.value.tags
    }
    const savingDetailPropertiesResponse = await dmsService.saveDocumentInfo(saveDocumentRequest);
    if (savingDetailPropertiesResponse.success) {
        let success = savingDetailPropertiesResponse.data;
        if (success) {
            toast.add({
                severity: "success",
                summary: "Operazione completata con successo",
                detail: "File salvato correttamente",
                life: 3000,
            });

            await showDocumentDetail(documentId);
            await loadItems();
        }
        else {
            toast.add({
                severity: "error",
                summary: "Errore durante l'operazione richiesta",
                detail: savingDetailPropertiesResponse.details.map(c => c.message).join(),
                life: 3000,
            });
        }
    }
    else {
        toast.add({
            severity: "error",
            summary: "Errore durante l'operazione richiesta",
            detail: savingDetailPropertiesResponse.details.map(c => c.message).join(),
            life: 3000,
        });
    }
    savingDocumentDetailProperties.value = false;

}
</script>