<template>
    <div class="row my-4">
        <div class="col-12 p-0">
            <div class="card text-white" style="height: 200px; background-color: #282A5B;">
                <KIcon class="icon-white position-absolute h-100" style="right: 0px; top: 0px; width: 30%; opacity: 0.2" icon="it-telephone" />
                <div class="card-img-overlay align-middle">
                    <h1 class="mt-5 ml-5">{{titolo}}</h1>
                    <div class="it-btn-container mt-2 ml-5 text-white">
                        <button type="button" class="btn btn-outline-white" @click="isModalOpen = !isModalOpen">{{testoBtn}}</button>
                    </div>
                </div>
            </div>

            <KModalSlide v-model="isModalOpen">
                <div style="width: 600px">
                    <h3 style="text-align: center">Contatta un consulente</h3>

                    <KTabs>
                        <!-- <KTab name="Prenota appuntamento">
                            <div class="card shadow h-100 pb-4"><div id="kd-widget"></div></div>
                        </KTab> -->
                        <KTab name="Scrivi email">
                            <form
                                id="formSendEmail"
                                class="needs-validation"
                                autocomplete="off"
                            >
                                <div class="row">
                                    <div class="cols-12 col-md-1 col-lg-12">
                                        <KInputText id="email-subject" v-model="sendEmailRequest.subject" labelText="Oggetto *"></KInputText>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-12">
                                        <KInputTextArea textClass="sendmail-body-textarea" id="email-body" v-model="sendEmailRequest.body" labelText="Il tuo messaggio *"></KInputTextArea>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="d-flex justify-content-center col-12">
                                        <button type="submit" @click="sendEmail" class="btn btn-primary mt-2">INVIA</button>
                                    </div>
                                </div>
                            </form>
                        </KTab>
                        <KTab name="Invio messaggio">
                            <form
                                id="formSendMessage"
                                class="needs-validation"
                                autocomplete="off"
                            >
                                <div class="row">
                                    <div class="cols-12 col-md-12 col-lg-12">
                                        <KInputTextArea textClass="sendmessage-body-textarea" id="message-body" v-model="sendMessageRequest.message" labelText="Il tuo messaggio *"></KInputTextArea>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="d-flex justify-content-center col-12">
                                        <button type="submit" @click="sendMessage" class="btn btn-primary mt-2">INVIA</button>
                                    </div>
                                </div>
                            </form>
                        </KTab>
                    </KTabs>
                </div>
            </KModalSlide>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { SendSupportEmailRequest, SendSupportMessageRequest } from '@/services/WholesaleModels';
import { wholesaleService } from '@/services/WholesaleServices';
import { KInputTextArea, KModalSlide } from '@k-digitale/kappa';
import { FormValidate } from "bootstrap-italia";
import { onMounted, ref } from 'vue';

//VARS
let validateEmailForm : any;
let validateMessageForm : any;

//REFS
const sendEmailRequest = ref<SendSupportEmailRequest>({} as SendSupportEmailRequest);
const sendMessageRequest = ref<SendSupportMessageRequest>({} as SendSupportMessageRequest);

//VUE
const props = withDefaults(
  defineProps<{
    titolo?: string,
    testoBtn?: string
  }>(), {
    titolo: 'Hai bisogno di una consulenza?',
    testoBtn: 'Chiedi al tuo consulente'
});

//METHODS
// const createEmailFormValidation = () => {
//     validateEmailForm = new FormValidate("#formSendEmail", {
//         errorFieldCssClass: "is-invalid",
//         errorLabelCssClass: "form-feedback",
//         errorLabelStyle: "",
//         focusInvalidField: true,
//     });

//     validateEmailForm.addField("#email-subject", [
//         {
//             rule: "required",
//             errorMessage: "L'oggetto della mail deve essere specificato",
//         },
//     ]);
//     validateEmailForm.addField("#email-body",
//         [
//             {
//                 rule: "required",
//                 errorMessage: "Il messaggio della mail deve essere specificato",
//             },
//         ],
//         {
//             successFieldCssClass: 'textarea-validation-success',
//         } );
// }

// const createMessageFormValidation = () => {
//     validateMessageForm = new FormValidate("#formSendMessage", {
//         errorFieldCssClass: "is-invalid",
//         errorLabelCssClass: "form-feedback",
//         errorLabelStyle: "",
//         focusInvalidField: true,
//     });

//     validateMessageForm.addField("#message-body",
//         [
//             {
//                 rule: "required",
//                 errorMessage: "Il contenuto del messaggio deve essere specificato",
//             },
//         ],
//         {
//             successFieldCssClass: 'textarea-validation-success',
//         } );
// }

const sendEmail = async () => {
    await validateEmailForm.revalidate();
    if(validateEmailForm.isValid){
        const result = await wholesaleService.sendSupportEmail(sendEmailRequest.value);
        if(result){
            isModalOpen.value = false;
        }
    }
}
const sendMessage = async () => {
    await validateMessageForm.revalidate();
    if(validateMessageForm.isValid){
        const result = await wholesaleService.sendSupportMessage(sendMessageRequest.value);
        if(result){
            isModalOpen.value = false;
        }
    }
}

const isModalOpen = ref(false);
</script>

<style>
.sendmail-body-textarea{
    height: 253px;
}
.sendmessage-body-textarea{
    height: 323px;
}

.textarea-validation-success{
    border-color: #008758 !important;
}
</style>