<template>
    <KCarousel :items="tvStore.latestVideos">
        <template v-slot:template="item">
            <div class="card-wrapper" style="width: 100%">
                <div class="card card-img no-after">
                    <div class="img-responsive-wrapper">
                        <div class="img-responsive">
                            <div class="img-wrapper">
                                <img :src="tvStore.getLargeThumbnailUrl(item.data)"
                                    title="titolo immagine" alt="descrizione immagine">
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <!-- <h5 class="card-title mb-0">{{item.data.title}}</h5> -->
                        <router-link class="read-more" style="position: unset;" :to="('video/' + item.data.id)">
                            <span class="text">{{item.data.title}}</span>
                            <span class="visually-hidden">{{item.data.title}}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </template>
    </KCarousel>
</template>

<script setup lang="ts">
//import KCarousel from './KCarousel.vue';
import { onMounted, ref } from 'vue';

import { useWebTvStore } from '../store';
const tvStore = useWebTvStore();

onMounted(() => {
    tvStore.getLatestVideos();
})

</script>