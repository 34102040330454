
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import addUiFramework from './ui-framework';
import App from './App.vue';
import { optionalModules } from './router';
import { usePlatformApi, useConfig, PlatformConfiguration, createKappaApp, KappaAppOptions } from '@k-digitale/kappa';
import { searchProviders } from './searchProviders';

import VueGoogleMaps from '@fawmi/vue-google-maps'

import 'primevue/resources/themes/fluent-light/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import './assets/fonts/fabric-icons.css';
import './assets/layout.scss';
import { useWholesaleUser } from './composables';

dayjs.extend(utc);

console.log("DP365 Extranet: Start initialization");

const { configureApiUrl, initializePlatform } = usePlatformApi();

configureApiUrl(import.meta.env.VITE_API_BASE_URL);

initializePlatform()
    .then((config: PlatformConfiguration) => {

        if (config != null) {
            const { loadConfig } = useConfig();
            loadConfig(config);
        }
        else {
            console.error("Intranet initialization failed. Platform configuration loading failed!");
            // TODO: creare router default e vista per errore
        }

        const options: KappaAppOptions = {
            appName: import.meta.env.VITE_APP_NAME,
            appType: "extranet",
            rootElement: App,
            optionalModules: optionalModules,
            routeBasePath: import.meta.env.VITE_ROUTE_BASE_PATH,
            globalSearchPlugins: searchProviders
        };
        const app = createKappaApp(options);
        addUiFramework(app);
        //app.use(VueGoogleMaps, { load: { key: import.meta.env.VITE_GMAPS_API_KEY } });
        app.mount('#app');

        // per inizializzazione gestione custom dati utente
        useWholesaleUser();
    })
    .catch((err: any) => {
        console.error("DP365 Extranet initialization failed! ")
    });
