<template>
    <Page :loading="loading">
        <PageHeader title="Glossario" :sticky="true">
            <template #secondary-actions>
                <div class="page-box">
                    <SelectButton v-model="selectedVoice" :options="storeGlossary.glossaryVoices" optionLabel="letter" class="glossario-buttons">
                        <template #option="slotProps">
                            <a :href="createAnchor(slotProps.option.letter)">{{slotProps.option.letter}}</a>
                        </template>
                    </SelectButton>
                </div>
            </template>
        </PageHeader>

        <div v-for="voce in storeGlossary.glossaryVoices" :key="voce.letter" >
            <div class="glossario-ancora"><a :id="voce.letter" :name="voce.letter"></a>{{voce.letter}}</div>

            <Accordion class="glossario-accordion" :id="createAccordionId(voce.letter)" :activeIndex="voce.activeIndex">
                <AccordionTab v-for="parola in voce.terms" :key="parola.id" :header="parola.voice"  :id="parola.voice">
                    <template #header>
                        <div class="glossario-ancora"><a :id="parola.voice" :name="parola.voice"></a></div>
                    </template>
                    <p v-html="parola.meaning"></p>
                </AccordionTab>
            </Accordion>

        </div>       
    </Page>
</template>

<script setup lang="ts">
import { ref,onMounted } from 'vue';
import { useRoute } from 'vue-router';

import { useGlossaryStore} from '../store';
import { Glossary, Term } from '../models';

const selectedVoice = ref<Glossary>(null);
const selectedTerm = ref<Term>(null);

const route = useRoute();
const loading = ref(false);
const storeGlossary = useGlossaryStore();



onMounted(async () => {
    loading.value = true;

    try {
        //leggo la voce che viene passata per poi aprire il tab giusto
        console.log(route);
        const paramVoice = route.query.voice;
        const paramLetter=route.query.letter;

        let voiceValue="";

        if(paramVoice!=null)
        {
            voiceValue=paramVoice.toString();
        }
        await storeGlossary.getGlossary(voiceValue);
        console.log(storeGlossary.glossaryVoices);
        
        if(paramLetter!=null)
        {
            console.log(paramLetter);
            document.getElementById(paramLetter.toString())!.scrollIntoView();
        }
        if(paramVoice!=null)
        {
            console.log(paramVoice);
            document.getElementById(paramVoice.toString())!.scrollIntoView();
        }
    }
    finally {
        loading.value = false;
    }
});


const createAnchor = (input: string) => {
    if(input == null) {
        return '';
    }
    return "#" + input;
}

const createAccordionId = (input: string) => {
    if(input == null) {
        return '';
    }
    return "accordion_" + input;
}
</script>

<style lang="scss">
.glossario-buttons{
    .p-button{
        padding: 2px 6px  !important;
        border: 0px solid #a19f9d !important;
        background-color: #F8F8F8 !important;
    }
    .p-button a{
            color: #000 !important;
    }
    .p-highlight {
        background: #0078d4 !important;
        border: 0px solid #a19f9d !important;
        color: #ffffff !important;
    }
        .p-highlight a {
        color: #ffffff !important;
    }
}

.glossario-ancora{
    
    font-weight:600;
    font-size:1.8rem;
    padding-top: 10px;
    padding-bottom: 10px;
}
.glossario-ancora a{
    color:#000; 
    display: block;
    position: relative;
    top: -200px;
    visibility: hidden;
}

.glossario-indice{
    color:#000;
    background-color: transparent;
    padding:2px 6px 2px 6px;

}
.glossario-indice:hover{
    color:#fff;
    background-color: #0078d4;
}
.glossario-indice-selected{
    color:#fff;
    background-color: #0078d4;
}
.glossario-accordion
{
    background-color: transparent;

    .p-accordion-tab{
        border: 0px solid #a19f9d  !important;
        border-bottom: 1px solid #dedede  !important;
        background-color: #fff ;

        .p-accordion-header{
            border: 0px solid #a19f9d  !important;
        }
    }

    .p-accordion-header .p-accordion-header-link {
        border: 0px solid #a19f9d !important;
        background: #fff !important;
    }
    .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {

        box-shadow: inset 0 0 0 0px #fff !important;
    }
    .p-accordion-content{
        border: 0px solid #a19f9d !important;
    }
    .pi{
        color:#0078d4;
    }
}
</style>