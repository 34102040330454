import { RouteRecordRaw } from "vue-router";

import Projects from './pages/Projects.vue';

const routes: RouteRecordRaw[] = [
    {
        name: 'projects-list',
        path: '/projects',
        component: Projects,
        meta: {
            requiresAuth: true,
            menu: {
                order: 1,
                label: 'Commesse',
                iconAsset: 'icons/ico-comunicazioni.svg'
            }
        },
    }
];

export default routes;