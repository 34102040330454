import { RouteRecordRaw } from "vue-router";

import Glossary from './pages/Glossary.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/glossary',
        component: Glossary,
        meta: { 
            requiresAuth: true,
            menu: {
                order: 2,
                label: 'Glossario',
                icon: 'TextField'
            }
        }
    }
];

export default routes;