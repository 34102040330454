import { RouteRecordRaw } from "vue-router";

import Videos from './pages/Video.vue';
import Video from './pages/VideoDetail.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/video',
        component: Videos,
        meta: { 
            requiresAuth: true,
            menu: {
                order: 1,
                label: 'Video',
                iconAsset: 'icons/ico_video.svg'
            }
        }
    },
    {
        path: '/video/:id',
        component: Video,
        meta: { 
            requiresAuth: true
        }
    }
];

export default routes;