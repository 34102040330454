<template>
    <Page>
        <PageHeader title="Servizi"></PageHeader>

        <DataTable :value="services" :loading="loading">
            <Column header="Descrizione" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7; max-width: 500px">
                <template #body="slotProps">
                    <span>{{
                        slotProps.data.description
                    }}</span>
                </template>
            </Column>
            <Column header="Stato" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7; max-width: 500px">
                <template #body="slotProps">
                    <span class="badge rounded-pill"
                        :class="slotProps.data.isActive ? 'bg-success' : 'bg-danger'">{{
                            slotProps.data.isActive ? 'Attivo' : 'Non attivo'
                        }}</span>
                </template>
            </Column>
            <Column header="Data inizio" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7;">
                <template #body="slotProps">
                    <span>{{ toDateString(slotProps.data.startDate) }}</span>
                </template>
            </Column>
            <Column header="Data fine" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82;background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7;">
                <template #body="slotProps">
                    <span>{{ toDateString(slotProps.data.endDate) }}</span>
                </template>
            </Column>
            <Column header="Motivo fine" header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7; max-width: 500px">
                <template #body="slotProps">
                    <span>{{ slotProps.data.endReason }}</span>
                </template>
            </Column>
            <Column header-class="fw-bold py-0 text-uppercase text-nowrap"
                header-style="color:#5C6F82; background-color:#F8F9F9; font-size:14px"
                body-style="border-color:#C7C7C7; max-width: 500px;text-align:right">
                <template #body="slotProps">
                    <button v-if="!slotProps.data.isActive" class="btn btn-primary btn-sm text-uppercase" type="button" @click="richiediServizio(slotProps.data)">Richiedi</button>
                </template>
            </Column>
        </DataTable>
    </Page>
</template>

<script setup lang="ts">

import { onMounted, ref } from 'vue';
import { useWholesaleUser } from '@/composables';
import { profileService } from '@/modules/profile/service';
import { useFilters } from '@k-digitale/kappa';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const { toDateString } = useFilters();
const { wholesaleUser, isUserCompany } = useWholesaleUser();
const loading = ref(false);
const services = ref<any[]>([]);

onMounted(async () => {
    loading.value = true;

    let companyResponse = await profileService.getCompany(wholesaleUser.value.companyId);
    if (companyResponse && companyResponse.success) {
        if (companyResponse.data.codiceCNA) {
            let serviceResponse = await profileService.getCompanyServices(companyResponse.data.codiceCNA);
            if (serviceResponse.success) {
                services.value = serviceResponse.data.services;
            }
        }
    }
    loading.value = false;
});

const richiediServizio = (srv: any) => {
    toast.add({
        severity: "info",
        summary: "Richiesta attivazione del servizio inoltrata correttamente.",
        life: 2000,
    });
}
</script>