import { defineStore } from 'pinia';
import { usePlatformApi, cmsService } from "@k-digitale/kappa";
import { Glossary, Term } from './models';

const { execApiCall } = usePlatformApi();
const glossaryCmsNodeName = "glossario";

export interface GlossaryState {
  searching: boolean,
  glossaryVoices: Glossary[],
  selectedVoice: Object
}

export const useGlossaryStore = defineStore('glossary-store', {
  state: () => ({
    searching: false,
    glossaryVoices: [],
    selectedVoice: {}
  } as GlossaryState),

  getters: {
    allTerms(state) : Term[] {
      let result: Term[] = [];

      if(state.glossaryVoices && state.glossaryVoices.length > 0) {
        state.glossaryVoices.forEach(voice => {
          if(voice.terms.length > 0) {
            result.push(...voice.terms);
          }
        });
      }

      return result;
    }
  },

  actions: {
    async getGlossary(currVoice:string) {
      try{
        this.searching = true;
        const response = await execApiCall<any>(cmsService.getTreeFromNode(glossaryCmsNodeName));
        let glossaryVoices: Glossary[] = [];
        if(response.childNodes) {
          for (let i = 0; i < response.childNodes.length; i++) {
            const node = response.childNodes[i];
            const voice: Glossary = {
              id:node.id,
              activeIndex:-1,
              letter: node.name,
              terms: []
            };
            
            for (let j = 0; j < node.childNodes.length; j++) {
              const termNode = node.childNodes[j];

              if(currVoice && currVoice.toLowerCase() == termNode.name.toLowerCase()){
                voice.activeIndex=j;
              }

              const term: Term = {
                id: termNode.id,
                voice: termNode.name,
                meaning: termNode.contents.find((x: any) => x.key == 'descrizione').value.value
              };

              voice.terms.push(term);
            }

            glossaryVoices.push(voice)
          }
        }
        this.glossaryVoices=glossaryVoices;
      }
      finally {
        this.searching = false;
      }
    }

  }
})