import { defineStore } from "pinia";
import { webTvService } from "./service";
import { WebTvVideo } from './models';
import { usePlatformApi } from "@k-digitale/kappa";

const { execApiCall } = usePlatformApi();
export interface WebTvState {
  // TODO
  latestVideos: WebTvVideo[],
  searchedVideos: WebTvVideo[],
  searching: boolean,
  advancedSearchTerm: string,
  advancedSearchResults: WebTvVideo[],
}

export const useWebTvStore = defineStore('kappa-web-tv-store', {
  state: () => ({
    latestVideos: [],
    searchedVideos: [],
    searching: false,
    advancedSearchTerm: '',
    advancedSearchResults: []
  } as WebTvState),

  getters: {
      //
  },

  actions: {
    getSmallThumbnailUrl(video: WebTvVideo) : string {
      return webTvService.buildVideoAssetUrl(video.smallThumbnailUrl);
    },
    getMediumThumbnailUrl(video: WebTvVideo) : string {
      return webTvService.buildVideoAssetUrl(video.mediumThumbnailUrl);
    },
    getLargeThumbnailUrl(video: WebTvVideo) : string {
      return webTvService.buildVideoAssetUrl(video.largeThumbnailUrl);
    },
    async getLatestVideos(numOfVideos: number = 3, skip: number = 0) : Promise<WebTvVideo[]> {
        const videos = await execApiCall(webTvService.getLatestVideos());
        if(videos && videos.length >= 0)
        {
            this.latestVideos = videos;
        }
        return videos;
    },
    async searchVideos(searchText: string, numOfVideos: number = 12, skip: number = 0) : Promise<WebTvVideo[]> {
      const videos = await execApiCall(webTvService.searchVideos(searchText, numOfVideos, skip));
      if(videos && videos.length >= 0)
      {
          this.searchedVideos = videos;
      }
      return videos;
    },
    async advancedSearch() : Promise<void> {
      this.searching = true;
      try {
        if(this.advancedSearchTerm && this.advancedSearchTerm.trim().length > 0) {
          this.advancedSearchResults = await execApiCall(webTvService.searchVideos(this.advancedSearchTerm, 12));
        }
        else {
          this.advancedSearchResults = await execApiCall(webTvService.getLatestVideos(12));
        }
      }
      catch(err) {
        console.error(err);
      }
      finally {
        this.searching = false;
      }
    }
  }
})