import { computed, ref, watch } from 'vue';
import { useUserStore, usePlatformApi } from '@k-digitale/kappa';
import { WholesaleUserInfo } from '@/models/WholesaleUserInfo';

export function useWholesaleUser() {
    
    const wholesaleUser = ref<WholesaleUserInfo>(null);
    const { user } = useUserStore();
    const { setSelectedCompany } = usePlatformApi();
    
    watch (
        () => user, 
        async () => {
            if(user) {
                wholesaleUser.value = user as any;
                setSelectedCompany(wholesaleUser.value.companyId);
            }
        },
        {immediate: true}
    );

    const isUserCompany = computed(() => {
        return !!wholesaleUser.value?.companyId;
    })

    return { wholesaleUser, isUserCompany }
}