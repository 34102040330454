import { ApiPagedResult, ApiResponse, BaseService } from "@k-digitale/kappa";
import { WebTvVideo } from './models';

class WebTvService extends BaseService {

    async getLatestVideos(numOfVideos: number = 3, skip: number = 0) : Promise<WebTvVideo[]> {
        
        const params = {
            skip: skip,
            numOfVideos: numOfVideos,
            excludeHomeVideos: false
        }
        const response = await this.tryExecute(
            this.api.get<WebTvVideo[]>("webtv/latest", { params: params })
        );
        
        return response!.data;
    }

    
    async getVideoDetails(id: string) : Promise<WebTvVideo> {
        const response = await this.tryExecute(
            this.api.get<WebTvVideo>(`webtv/video/${id}`)
        );
        
        return response!.data;
    }

    async getVideoSources(id: string) : Promise<string[]> {
        const response = await this.tryExecute(
            this.api.get<string[]>(`webtv/video/${id}/sources`)
        );
        
        return response!.data;
    }

    async getRelatedVideos(id: string) : Promise<WebTvVideo[]> {
        const response = await this.tryExecute(
            this.api.get<WebTvVideo[]>(`webtv/video/${id}/related`)
        );
        
        return response!.data;
    }

    async updateVideoWatchCounter(id: string) {
        const response = await this.tryExecute(
            this.api.post(`webtv/video/${id}/watch`)
        );
    }

    async searchVideos(searchText: string, numOfVideos: number = 15, skip: number = 0) : Promise<WebTvVideo[]> {
        
        const params = {
            searchText: searchText,
            skip: skip,
            numOfVideos: numOfVideos
        }
        const response = await this.tryExecute(
            this.api.get<WebTvVideo[]>("webtv/simple-search", { params: params })
        );
        
        return response!.data;
    }

    buildVideoAssetUrl(thumbnailPath: string) : string {
        return `${this.api.defaults.baseURL}webtv/${thumbnailPath}`;
    }
}

export const webTvService = new WebTvService();