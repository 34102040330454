import { GlobalSearchPlugin, GlobalSearchResult } from "@k-digitale/kappa";
import { useGlossaryStore } from "./store";

class GlossarySearchProvider implements GlobalSearchPlugin {

    source = "glossary";
    sourceDescription = "Glossary";

    _lastSearch = "";

    async searchAsync(searchText: string) : Promise<GlobalSearchResult[]> {
        const storeGlossary = useGlossaryStore();
        if(storeGlossary.allTerms == null || storeGlossary.allTerms.length == 0)
        {
            await storeGlossary.getGlossary(null);
        }

        const results = storeGlossary
            .allTerms
            .filter(term => term.voice.toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            .map(term => { 
                return {
                    source: this.source,
                    title: term.voice,
                    description: term.meaning,
                    url: "/glossary?voice=" + encodeURIComponent(term.voice)
                }
            });

        this._lastSearch = searchText;
        return results;
    }

    async loadMoreAsync() : Promise<GlobalSearchResult[]> {
        const results : GlobalSearchResult[] = [];
        return results;
    }    
}

export default new GlossarySearchProvider();