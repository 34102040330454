<template>
    <Page>
        <PageHeader :title="video ? video.title : 'Caricamento video..'"></PageHeader>

        <KWebTvVideoPlayer :idVideo="idVideo" />

        <div v-html="video && video.description"></div>
    </Page>
</template>

<script setup lang="ts">
import KWebTvVideoPlayer from './KWebTvVideoPlayer.vue';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { webTvService } from "../service";
import { watch } from 'fs';
import { WebTvVideo } from '../models';

const route = useRoute();

const idVideo = ref<string>(route.params.id as string);

const video = ref<WebTvVideo>();

onMounted(async () => {
    video.value = await webTvService.getVideoDetails(idVideo.value);
})

</script>

