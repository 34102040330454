<template>
    <Page :loading="loading">
        <div class="row p-3 bg-light">
            <!-- <div class="col-12 col-md-4 col-lg-2 d-flex flex-column">
                <form class="upload-dragdrop" method="post" action="" enctype="multipart/form-data" id="uploadChangeStateTarget" data-bs-upload-dragdrop>
                    <div class="upload-dragdrop-image">
                        <img src="/upload-drag-drop-icon.svg" class="" alt="descrizione immagine" aria-hidden="true">
                        <div class="upload-dragdrop-loading">
                            <div class="progress-donut" data-bs-progress-donut></div>
                        </div>
                        <div class="upload-dragdrop-success">
                            <KIcon icon="it-check" aria-hidden="true" />
                        </div>
                    </div>
                    <input value="Submit" type="submit" class="d-none" />
                </form>
                <a class="ml-4 mt-5" role="button" title="Cambia Immagine" @click="testAnimation">
                    <span class="text">CAMBIA IMMAGINE
                        <span class="visually-hidden">CAMBIA IMMAGINE</span>
                    </span>
                </a>
            </div> -->
            <div class="col-12 col-md-5 col-lg-6 d-flex flex-column mt-4">
                <h5>Ragione Sociale</h5>
                <h2>{{company.ragioneSociale}}</h2>
                <!-- <h5>Iscritto dal <b>{{iscrittoDal}}</b></h5> -->
            </div>
            <div class="col-12 col-md-3 col-lg-4 d-flex align-items-center ">
                <button type="button" class="btn btn-primary px-5 col-12">CONSULTA L'ESPERTO</button>
            </div>
        </div>
        <br/>
        
        <div class="border p-4">
            <KSectionHeader title="Dati anagrafici"></KSectionHeader>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Codice Ateco</label><br />
                    <span>{{ company.codiceAteco }} - {{ company.descrizioneCodiceAteco }}</span>
                </template>
                <template #col2>
                    <label class="fw-semibold">Codice Ateco Fin.</label><br />
                    <span>{{ company.codiceAtecoFin }} - {{ company.descrizioneCodiceAtecoFin }}</span>
                </template>
            </K2ColContainer>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Numero REA</label><br />
                    <span>{{ company.numeroREA }}</span>
                </template>
                <template #col2>
                    <label class="fw-semibold">Natura impresa</label><br />
                    <span>{{ company.naturaGiuridica }}</span>
                </template>
            </K2ColContainer>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Codice fiscale</label><br />
                    <span>{{ company.codiceFiscale }}</span>
                </template>
                <template #col2>
                    <label class="fw-semibold">Partita Iva</label><br />
                    <span>{{ company.partitaIVA }}</span>
                </template>
            </K2ColContainer>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Indirizzo</label><br />
                    <span>{{ company.indirizzo }}</span>
                </template>
                <template #col2>
                    <label class="fw-semibold">CAP</label><br />
                    <span>{{ company.cap }}</span>
                </template>
            </K2ColContainer>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Comune</label><br />
                    <span>{{ company.comune }}</span>
                </template>
                <template #col2>
                    <label class="fw-semibold">Provincia</label><br />
                    <span>{{ company.provincia }}</span>
                </template>
            </K2ColContainer>
        </div>
        <br />
        <div class="border p-4">
            <KSectionHeader title="Contatti"></KSectionHeader>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Telefono</label><br />
                    <span>{{ company.numeroTelefono }}</span>
                </template>
                <template #col2>
                    <label class="fw-semibold">Cellulare</label><br />
                    <span>{{ company.numeroCellulare }}</span>
                </template>
            </K2ColContainer>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Email</label><br />
                    <span>{{ company.email }}</span>
                </template>
                <template #col2>
                    <label class="fw-semibold">Pec</label><br />
                    <span>{{ company.pec }}</span>
                </template>
            </K2ColContainer>
            <K2ColContainer :ratio="TwoColumnLayoutRatio.oneOne">
                <template #col1>
                    <label class="fw-semibold">Sito</label><br />
                    <span>{{ company.sito }}</span>
                </template>
                <template #col2>
                </template>
            </K2ColContainer>
        </div>
            
    </Page>
</template>

<script setup lang="ts">

//IMPORTS GENERAL
import { nextTick, onMounted, ref, watch } from "vue";
import { UploadDragDrop, FormValidate } from 'bootstrap-italia';
import { profileService } from "../service";
import { TwoColumnLayoutRatio, useFilters } from "@k-digitale/kappa";
import { useWholesaleUser, useValidationHelper } from "@/composables";

let validate : any;

const { toDateString } = useFilters();
const { wholesaleUser, isUserCompany } = useWholesaleUser();

const isRegistryDataInEditMode = ref(false);

const company = ref<any>({});
const loading = ref(false);

onMounted(async () => {
    if(isUserCompany.value){
        loading.value = true;
        //company.value = await profileService.getCompany(wholesaleUser.value.companyId);
        await loadCompany();
        loading.value = false;
    }
});

watch(isRegistryDataInEditMode, async (newValue) => {
    if(newValue){
        await nextTick();
        //defineValidations();
    }
});

const loadCompany = async () => {
    loading.value = true;

    let companyResponse = await profileService.getCompany(wholesaleUser.value.companyId);
    if (companyResponse.success) {
        company.value = companyResponse.data;
    }
    else {
        //
    }
    loading.value = false;
}

const saveCompanyRegistryData = async () => {
    // if(!companyToSave.value.fiscalCode && !companyToSave.value.partitaIVA){
    //     isRegistryDataValid.value = false;
    //     registryDataValidationMessage.value = 'Almeno uno tra codice fiscale e partita IVA deve essere inserito.';
    //     return;
    // }
    // else
    // {
    //     isRegistryDataValid.value = true;
    // }

    // await validate.revalidate();
    // if(validate.isValid){
    //     loading.value = true;
    //     const response = await registriesService.saveCompany(companyToSave.value);
    //     if(response){
    //         stopEditRegistryData();
    //         stopEditContacts();
    //         company.value = await profileService.getCompany(cnaUser.value.companyId);
    //     }
    //     loading.value = false;
    // }
}

const saveCompanyContacts = async () => {
    // loading.value = true;
    // const response = await registriesService.saveCompany(companyToSave.value);
    // if(response){
    //     stopEditRegistryData();
    //     stopEditContacts();
    //     company.value = await profileService.getCompany(cnaUser.value.companyId);
    // }
    // loading.value = false;
}

const startEditRegistryData = () => {
    // isRegistryDataValid.value = true;
    // companyToSave.value = mapCompanyToSaveRequest(company.value);
    // isRegistryDataInEditMode.value = true;
}

const stopEditRegistryData = () => {
    // companyToSave.value = {} as SaveCompanyRequest;
    // isRegistryDataInEditMode.value = false;
}

const startEditContacts = () => {
    // companyToSave.value = mapCompanyToSaveRequest(company.value);
    // isContactsInEditMode.value = true;
}

const stopEditContacts = () => {
    // companyToSave.value = {} as SaveCompanyRequest;
    // isContactsInEditMode.value = false;
}
</script>

<style lang="scss">
.profile-image {
    width: 80%;
    height: 80%;
}
.profile-payment-state {
    width: 15px;
    height: 15px;
    border-radius: 10px;
}
.height-title-profile-data {
    height: 40px;
}
.icon-chat-anagrafica {
    right: -50px;
    top: 50px;
}
</style>