import { ApiPagedListResult, ApiResponse, ApiResult, BaseService } from '@k-digitale/kappa';
import { DMSDocument, GetCategoriesDMSRequest, GetCategoriesDMSResponse, GetFolderContentDMSResponse, GetFolderContentDMSRequest, SearchDMSDocumentsRequest, DownloadDocumentDMSRequest, DMSDocumentOrFolder, GetDocumentDMSRequest, SetDocumentPropertiesDMSRequest, DeleteDocumentDMSRequest, DeleteFolderDMSRequest, UploadDMSDocumentRequest, UploadDMSDocumentResponse, CreateFolderDMSRequest, DMSVirtualFileSystemFolder, RenameFolderDMSRequest } from '../models/Models';


class DMSService extends BaseService {
    async searchDocuments(request: SearchDMSDocumentsRequest): Promise<ApiPagedListResult<DMSDocumentOrFolder>> {

        const response = await this.tryExecute(
            this.api.get<ApiPagedListResult<DMSDocumentOrFolder>>("/DMS/Home/SearchDocuments", { params: request })
        );
        return response ? response.data ? response.data : null : null;
    }

    async getFolderContent(request: GetFolderContentDMSRequest): Promise<ApiResult<GetFolderContentDMSResponse>> {

        const response = await this.tryExecute(
            this.api.get<ApiResult<GetFolderContentDMSResponse>>("/DMS/Home/GetDir", { params: request })
        );
        return response ? response.data ? response.data : null : null;
    }

    async getDocumentDetail(request: GetDocumentDMSRequest): Promise<ApiResult<DMSDocument>> {
        const response = await this.tryExecute(
            this.api.get<ApiResult<DMSDocument>>("/DMS/Home/DocumentInfo", { params: request })
        );
        return response ? response.data ? response.data : null : null;
    }

    async getCategories(request: GetCategoriesDMSRequest): Promise<ApiResult<GetCategoriesDMSResponse>> {
        const response = await this.tryExecute(
            this.api.get<ApiResult<GetCategoriesDMSResponse>>("/DMS/Home/GetLookups", { params: request })
        );
        return response ? response.data ? response.data : null : null;
    }

    async downloadDocument(request: DownloadDocumentDMSRequest) {
        const response = await this.tryExecute(
            this.api.get<Blob>("/DMS/Home/DownloadDocument", { params: request, responseType: 'blob' })
        );
        return response;
    }

    async saveDocumentInfo(request: SetDocumentPropertiesDMSRequest): Promise<ApiResult<boolean>> {
        const response = await this.tryExecute(
            this.api.post<ApiResult<boolean>>("/DMS/Home/SetDocumentProperties", request)
        );
        return response ? response.data ? response.data : null : null;
    }

    async uploadDocument(request: FormData) {
        const response = await this.tryExecute(
            this.api.post<ApiResult<UploadDMSDocumentResponse>>("/DMS/Home/UploadDocument", request, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        );
        return response ? response.data ? response.data : null : null;
    }

    async deleteDocument(request: DeleteDocumentDMSRequest): Promise<ApiResult<boolean>> {
        const response = await this.tryExecute(
            this.api.post<ApiResult<boolean>>("/DMS/Home/DeleteDocument", request)
        );
        return response ? response.data ? response.data : null : null;
    }

    async createFolder(request: CreateFolderDMSRequest): Promise<ApiResult<DMSVirtualFileSystemFolder>> {
        const response = await this.tryExecute(
            this.api.post<ApiResult<DMSVirtualFileSystemFolder>>("/DMS/Home/SetDir", request)
        );
        return response ? response.data ? response.data : null : null;
    }

    async renameFolder(request: RenameFolderDMSRequest): Promise<ApiResult<boolean>> {
        const response = await this.tryExecute(
            this.api.post<ApiResult<boolean>>("/DMS/Home/RenameDir", request)
        );
        return response ? response.data ? response.data : null : null;
    }

    async deleteFolder(request: DeleteFolderDMSRequest): Promise<ApiResult<boolean>> {
        const response = await this.tryExecute(
            this.api.post<ApiResult<boolean>>("/DMS/Home/RemoveDir", request)
        );
        return response ? response.data ? response.data : null : null;
    }
}

export const dmsService = new DMSService();