import { RouteRecordRaw } from "vue-router";

import ProfileHome from './pages/Profile.vue';

const cnaRoutes: RouteRecordRaw[] = [
    {
        path: '/profile',
        component: ProfileHome,
        meta: {
            requiresAuth: true,
            menu: {
                order: 1,
                label: 'Account',
                iconAsset: '/icons/ico_anagrafica.svg'
            }
        }
    }
];

export default cnaRoutes;